var body = document.body;
var navOpen = document.getElementById('nav-open');
var navClose = document.getElementById('nav-close');
var navContainer = document.getElementById('navigation');
var navigation = navContainer.getElementsByTagName("nav")[0];

navOpen.onclick = function () {
    navContainer.classList.add("inset-0");
    navContainer.classList.add("bg-gray-200");
    navigation.classList.remove("-right-full");

    body.classList.add("overflow-y-hidden");
};

navClose.onclick = function () {
    closeMenu();
};

window.addEventListener("orientationchange", function (event) {
    closeMenu();
});

function closeMenu() {
    navContainer.classList.remove("inset-0");
    navContainer.classList.remove("bg-gray-200");
    navigation.classList.add("-right-full");
    body.classList.remove("overflow-y-hidden");
}

var elements = document.getElementsByClassName("toggle-dropdown");

var toggleDropdown = function () {
    var elements = this.parentNode.querySelector('.dropdown')
    elements.classList.toggle("hidden");

    this.querySelectorAll('.up, .down').forEach(b => {
        b.classList.toggle("hidden");
    });
}

for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', toggleDropdown, false);
}
